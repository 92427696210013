import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setQuery: ['query'],
});

export const INITIAL_STATE = {
  query: '',
};

export const setQuery = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    query: action.query,
  };
};

export const HANDLERS = {
  [Types.SET_QUERY]: setQuery,
};
export default createReducer(INITIAL_STATE, HANDLERS);
