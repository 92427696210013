import React from 'react';
import { Box } from '@mui/material';

const RuleTerms = ({ color = '#424242', ...rest }) => {
  return (
    <Box component="div" display="flex" {...rest}>
      <a
        href="https://www.mobiauto.com.br/institucional/termos-de-uso"
        rel="noreferrer"
      >
        <Box
          component="p"
          fontSize={{ xs: '12px' }}
          fontWeight={600}
          color={color}
        >
          Termo de uso
        </Box>
      </a>
      <a
        href="https://www.mobiauto.com.br/institucional/privacidade"
        rel="noreferrer"
      >
        <Box
          component="p"
          fontSize={{ xs: '12px' }}
          fontWeight={600}
          color={color}
        >
          Política de privacidade
        </Box>
      </a>
      <a href="/" rel="noreferrer">
        <Box
          component="p"
          fontSize={{ xs: '12px' }}
          fontWeight={600}
          color={color}
        >
          Política de cookies
        </Box>
      </a>
    </Box>
  );
};

export default RuleTerms;
