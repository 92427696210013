import { Box } from '@mui/material';
import Footer from 'components/atoms/Footer';
import { Outlet, useLocation } from 'react-router-dom';
import Header from './components/HeaderBase';
import { useMemo } from 'react';

const LayoutComponent = (props: any) => {
  const location = useLocation();

  const myAccountPage = useMemo(() => {
    return location.pathname;
  }, [location.pathname]);

  const paths = ['/passe-carros/minha-conta', '/passe-carros/regulamento'];

  return (
    <>
      <Header />
      <Box
        component="main"
        mt={paths.includes(myAccountPage) ? 0 : 2}
        mb={paths.includes(myAccountPage) ? 0 : 3}
        minHeight={{
          xs: '100%',
          md: 'calc(100vh - 328px)',
        }}
      >
        <Outlet />
      </Box>
      <Footer />
    </>
  );
};

export default LayoutComponent;
