import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import BidProposalService from 'services/ProposalService';
import { Creators as UICreators } from 'store/uiStore';
import { Types as UserTypes, Creators as UserCreators } from 'store/userStore';

function* getMobicoinBalance() {
  try {
    const { currentDealer } = yield select((state) => state.userStore);
    yield put(UserCreators.setFetchingBalance(true));
    const response = yield call(
      BidProposalService.getMobiCoinBalance,
      currentDealer.id,
    );
    if (response.success) {
      yield put(
        UserCreators.setMobicoinBalanceToCurrentDealer(
          response?.data?.balance || 0,
        ),
      );
      return;
    }
    yield put(
      UICreators.snackbarManagement(
        'error',
        'Ocorreu um erro na consulta do saldo de mobicoin.',
        true,
      ),
    );
  } catch {
    yield put(
      UICreators.snackbarManagement(
        'error',
        'Ocorreu um erro na consulta do saldo de mobicoin.',
        true,
      ),
    );
  } finally {
    yield put(UserCreators.setFetchingBalance(false));
  }
}

export default all([
  takeLatest(UserTypes.GET_MOBICOIN_BALANCE, getMobicoinBalance),
]);
