import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setFavoriteList: ['favoriteList'],
  setItemToFavorite: ['dealId'],
  setItemToFavoriteLoading: ['setItemToFavoriteLoading'],
  setFavoriteLoading: ['favoriteLoading'],
  getFavorites: ['favoritesData'],
});

export const INITIAL_STATE = {
  favoriteData: {
    loading: false,
    data: [],
  },
};

export const setFavoriteList = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    favoriteData: {
      ...state?.favoriteData,
      data: action?.favoriteList,
    },
  };
};

export const setFavoriteLoading = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    favoriteData: {
      ...state?.favoriteData,
      loading: action?.favoriteLoading,
    },
  };
};

export const HANDLERS = {
  [Types.SET_FAVORITE_LIST]: setFavoriteList,
  [Types.SET_FAVORITE_LOADING]: setFavoriteLoading,
};
export default createReducer(INITIAL_STATE, HANDLERS);
