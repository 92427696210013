import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setCurrentProposalData: ['proposalData'],
  setTotalElements: ['totalElements'],
  setStatusFilter: ['statusFilter'],
  setLoading: ['loading'],
  setPage: ['page'],
  setSize: ['size'],
});

export const INITIAL_STATE = {
  proposalData: [],
  totalElements: 0,
  size: 20,
  page: 0,
  totalPages: 0,
  status: 'ALL',
  loading: false,
};

export const setCurrentProposalData = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
      proposalData: action.proposalData,
  };
};

export const setTotalElements = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
      totalElements: action.totalElements,
  };
};

export const setStatusFilter = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
      status: action.statusFilter,
  };
};

export const setLoading = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
      loading: action.loading,
  };
};

export const setPage = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
      page: action.page,
  };
};

export const setSize = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
      size: action.size,
  };
};

export const HANDLERS = {
  [Types.SET_CURRENT_PROPOSAL_DATA]: setCurrentProposalData,
  [Types.SET_TOTAL_ELEMENTS]: setTotalElements,
  [Types.SET_STATUS_FILTER]: setStatusFilter,
  [Types.SET_LOADING]: setLoading,
  [Types.SET_PAGE]: setPage,
  [Types.SET_SIZE]: setSize,
};
export default createReducer(INITIAL_STATE, HANDLERS);
