import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setId: ['id'],
  setMakeName: ['makeName'],
  setModelName: ['modelName'],
  setDealerName: ['dealerName'],
  setPlate: ['plate'],
  setBidValue: ['bidValue'],
  setFipeValue: ['fipeValue'],
  setBuyNowValue: ['buyNowValue'],
});

export const INITIAL_STATE = {
  id: null,
  makeName: '',
  modelName: '',
  dealerName: '',
  plate: '',
  bidValue: null,
  fipeValue: null,
  buyNowValue: null,
};

export const setId = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    id: action.id,
  };
};

export const setMakeName = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    makeName: action.makeName,
  };
};

export const setModelName = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    modelName: action.modelName,
  };
};

export const setDealerName = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    dealerName: action.dealerName,
  };
};

export const setPlate = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    plate: action.plate,
  };
};

export const setBidValue = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    bidValue: action.bidValue,
  };
};

export const setFipeValue = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    fipeValue: action.fipeValue,
  };
};

export const setBuyNowValue = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    buyNowValue: action.buyNowValue,
  };
};

export const HANDLERS = {
  [Types.SET_ID]: setId,
  [Types.SET_MAKE_NAME]: setMakeName,
  [Types.SET_MODEL_NAME]: setModelName,
  [Types.SET_DEALER_NAME]: setDealerName,
  [Types.SET_PLATE]: setPlate,
  [Types.SET_BID_VALUE]: setBidValue,
  [Types.SET_FIPE_VALUE]: setFipeValue,
  [Types.SET_BUY_NOW_VALUE]: setBuyNowValue,
};

export default createReducer(INITIAL_STATE, HANDLERS);
