import { createStore, compose, combineReducers, applyMiddleware } from 'redux';
import { reactotron } from '../reactotron';
import createSagaMiddleware from 'redux-saga';
import locationStore from './locationStore';
import userStore from './userStore';
import filterStore from './filterStore';
import proposalStore from './proposalStore';
import proposalVehicleStore from './proposalVehicleStore';
import favoriteStore from './favoriteStore';
import UIStore from './uiStore';
import sagas from './sagas';

const reducers = combineReducers({
  locationStore,
  userStore,
  filterStore,
  proposalStore,
  proposalVehicleStore,
  favoriteStore,
  UIStore,
});

const sagaMiddleware = createSagaMiddleware();

const middlewares = [applyMiddleware(sagaMiddleware)];

if (process.env.NODE_ENV === 'development') {
  const reactotronMiddleware = reactotron.createEnhancer();
  middlewares.push(reactotronMiddleware);
}

const store = createStore(reducers, compose(...middlewares));

sagaMiddleware.run(sagas);

export default store;
