import request from 'helpers/Request';
import getEnvVarByName from 'helpers/getEnvVarByName';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const domain = getEnvVarByName('API_CORPORATIVESALE_BASE_URL');
const serviceName = 'proposal';

export default class BidProposalService {
  static postBidProposal = (buyerId: any, proposalResaleBidDto: any) => {
    return request
      .post(
        `${domain}/buyer/${buyerId}/resale/proposal/v1.0/bid`,
        proposalResaleBidDto,
      )
      .then(buildHTTPResponseHandler(`${serviceName}.postBidProposal()`));
  };

  static acceptNegotiation = (
    buyerId: string | number,
    bidId: string | number,
    dealId: string | number,
    proposalResaleId: string | number,
  ) => {
    return request
      .post(
        `${domain}/buyer/${buyerId}/resale/proposal/v1.0/quick-sale/accept-negotiation`,
        {},
        {
          params: {
            bidId,
            dealId,
            proposalResaleId,
          },
        },
      )
      .then(buildHTTPResponseHandler(`${serviceName}.acceptNegotiation()`));
  };
  static cancelProposalMobija = (
    buyerId: number,
    dealId: number,
    messageCancellation: string,
    negotiationId: number,
  ) => {
    return request
      .post(
        `${domain}/buyer/${buyerId}/resale/proposal/v1.0/negotiation/cancel`,
        {
          dealId,
          messageCancellation,
          negotiationId,
        },
      )
      .then(buildHTTPResponseHandler(`${serviceName}.cancelProposalMobija()`));
  };
  static getMobiCoinBalance = (buyerId: string | number) => {
    return request
      .get(`${domain}/mobicoin/v1.0/balance?buyerId=${buyerId}`, {})
      .then(buildHTTPResponseHandler(`${serviceName}.getMobiCoinBalance()`));
  };

  static createBillAndCustomer = (dealerId: string | number, amountPrice) => {
    return request
      .post(
        `${domain}/mobicoin/v1.0/credit?buyerId=${dealerId}&quantity=${amountPrice}`,
        {},
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getMobiCoinBalance()`));
  };

  static getUnitPrice = () => {
    return request
      .get(`${domain}/mobicoin/v1.0/unit_price`, {})
      .then(buildHTTPResponseHandler(`${serviceName}.getUnitPrice()`));
  };

  static getQuickSaleNegotiationInfos = ({ dealerId, dealId }) => {
    return request
      .get(`${domain}/buyer/${dealerId}/resale/proposal/v1.0/bid/last`, {
        dealId,
      })
      .then(buildHTTPResponseHandler(`${serviceName}.getUnitPrice()`));
  };

  static getBiggestProposal = async (dealId: number | string, buyerId) => {
    return request
      .get(
        `${domain}/buyer/${buyerId}/resale/proposal/api/resale/proposal/v1.0/bid/max`,
        {
          dealId,
        },
      )
      .then((response) => response);
  };

  static getProposalLimit = async (buyerId: number | string) => {
    return request
      .get(`${domain}/buyer/${buyerId}/resale/proposal/v1.0/bids/available`, {})
      .then((response) => response);
  };
}
