import Request from 'helpers/Request';
import getEnvVarByName from 'helpers/getEnvVarByName';

const domain = getEnvVarByName('API_SEARCH_BASE_URL');

export default class LocationService {
  static getUserLocationByIp = async () => {
    const response = await Request.get(
      `${domain}/location/v1.0/mylocation`,
      {},
    );
    if (response.ok) {
      return { success: true, data: response.data };
    }
    return { success: false, data: null };
  };

  static getStates = async () => {
    const response = await Request.get(`${domain}/location/v1.0/states`, {});
    if (response.ok) {
      return { success: true, data: response.data };
    }
    return { success: false, data: null };
  };

  static getCitiesByText = async (text: string) => {
    const response = await Request.get(
      `${domain}/location/v1.0/city/suggestion/${text}`,
      {},
    );
    if (response.ok) {
      return { success: true, data: response.data };
    }
    return { success: false, data: null };
  };
}
