import React from 'react';

import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import ButtonBase from '@mui/material/ButtonBase';
import { Logout } from '@mui/icons-material';
import LaunchIcon from '@mui/icons-material/Launch';
import { Link, Typography } from '@mui/material';

import { logout } from 'auth/AuthService';
import EditSquare from 'assets/icons/editSquare';
import { ReactComponent as OfficeStampDocument } from 'assets/office_stamp_document.svg';

const UserInfoComponent = ({ selectModalActions, open }) => {
  const currentUser = useSelector((state: any) => state.userStore?.currentUser);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const id = open ? 'user-menu' : undefined;

  return (
    <>
      <Box component="div" display="flex" alignItems="center" gap="8px">
        <Box component="div" height="32px" width="1px" bgcolor="#E0E0E0" />
        <ButtonBase onClick={handleClick} aria-describedby="user-menu">
          <Avatar alt="User">{currentUser?.firstName?.slice(0, 1)}</Avatar>
          <Typography component="p" fontWeight={700} fontSize="14px" ml={2}>
            {currentUser?.firstName}
          </Typography>
        </ButtonBase>
        <Popover
          id={id}
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          sx={{ marginTop: '8px' }}
        >
          <Box
            component="div"
            display="flex"
            flexDirection="column"
            p={1}
            width={264}
            max-width={264}
            onClick={handleClose}
          >
            <Link
              component={RouterLink}
              to="/passe-carros/minha-conta"
              color="common.black"
              fontWeight={700}
              padding="14px 24px"
              display="flex"
              alignItems="center"
              underline="none"
            >
              <Box height="fit-content" lineHeight={0} mr={1}>
                <EditSquare />
              </Box>
              Minha conta
            </Link>
            <Link
              component={RouterLink}
              to="/passe-carros/regulamento"
              color="common.black"
              fontWeight={700}
              padding="14px 24px"
              display="flex"
              alignItems="center"
              underline="none"
              style={{ cursor: 'pointer' }}
            >
              <Box height="fit-content" lineHeight={0} mr={1}>
                <OfficeStampDocument />
              </Box>
              Regulamento
              <Box height="fit-content" lineHeight={0} mr={0} ml={'auto'}>
                <LaunchIcon />
              </Box>
            </Link>
            <Box
              component="div"
              color="common.black"
              padding="14px 24px"
              display="flex"
              alignItems="center"
              sx={{
                '& button': {
                  fontWeight: 700,
                  fontSize: '16px',
                },
              }}
            >
              <ButtonBase onClick={() => logout()}>
                <Box height="fit-content" lineHeight={0} mr={1}>
                  <Logout />
                </Box>
                Sair
              </ButtonBase>
            </Box>
          </Box>
        </Popover>
      </Box>
    </>
  );
};

export default UserInfoComponent;
