import { all, call, put, takeLatest } from 'redux-saga/effects';
import FavoritesServices from 'services/FavoritesServices';
import { Creators as UICreators } from 'store/uiStore';
import {
  Types as UserTypes,
  Creators as FavoriteCreators,
} from 'store/favoriteStore';

function* getFavorites() {
  yield put(FavoriteCreators.setFavoriteLoading(true));
  const response = yield call(FavoritesServices.getFavoriteList);
  if (response?.ok) {
    const deals = response?.data?.map((item) => {
      return { id: item?.id, ...item?.deal };
    });
    yield put(FavoriteCreators.setFavoriteList(deals));
    yield put(FavoriteCreators.setFavoriteLoading(false));
    return response?.data;
  }
  yield put(FavoriteCreators.setFavoriteLoading(false));
  if (!response?.ok) {
    yield put(FavoriteCreators.setFavoriteList([]));
    return;
  }
}

function* setItemInFavorite(params) {
  const response = yield call(
    FavoritesServices.postInFavoriteList,
    params?.dealId,
  );
  if (response?.ok) {
    yield put(
      UICreators.snackbarManagement('favorite', response?.data?.message, true),
    );
    yield put(FavoriteCreators.getFavorites());
    return;
  }
  // yield put(
  //   UICreators.snackbarManagement('error', 'Ocorreu um erro inesperado.', true),
  // );
  yield put(
    UICreators.snackbarManagement(
      'error',
      'Ocorreu um erro na busca dos favoritos.',
      true,
    ),
  );
}

export default all([
  takeLatest(UserTypes.GET_FAVORITES, getFavorites),
  takeLatest(UserTypes.SET_ITEM_TO_FAVORITE, setItemInFavorite),
]);
