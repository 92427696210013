import request from 'helpers/Request';
import getEnvVarByName from 'helpers/getEnvVarByName';

const domain = getEnvVarByName('API_MOBIGESTOR_BASE_URL');
const passeCarrosDomain = getEnvVarByName('API_PASSECARROS_BASE_URL');

type giroPanPayloadProps = {
  dealerId: number;
  targetValue: number;
};

export default class DealerService {
  static GetDealers = (q = '', page = 0) => {
    return request
      .get(
        `${domain}/dealer/v1.0/dealers/consultor?page=${page}&size=${20}&q=${q}`,
        {},
      )
      .then((response) => response);
  };

  static getDealerById = (dealerId) => {
    return request
      .get(`${domain}/dealer/v1.0/dealer/${dealerId}`, {})
      .then((response) => response);
  };

  static postGiroPan = ({ dealerId, targetValue }: giroPanPayloadProps) => {
    return request
      .post(
        `${passeCarrosDomain}/dealer/v1.0/giropan/pre-analysis`,
        {},
        {
          params: {
            dealerId,
            targetValue,
          },
        },
      )
      .then((response) => response);
  };
}
