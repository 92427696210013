import React from 'react';

const useModal = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };
  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return { isOpen, handleOpenModal, handleCloseModal, open, close };
};

export default useModal;
