import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Creators as UICreators } from 'store/uiStore';
import { Box } from '@mui/system';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

function Alert(props) {
  return (
    <Box>
      <MuiAlert elevation={6} variant="filled" {...props} />
    </Box>
  );
}

function getAlertByType(
  type: string,
  message: string,
  handleClose: () => void,
) {
  switch (type) {
    case 'success':
      return (
        <Box>
          <Alert
            icon={
              <div style={{ lineHeight: '22px', fontSize: '24px' }}>😀</div>
            }
            handleClose={handleClose}
            severity="success"
          >
            {message}
          </Alert>
        </Box>
      );
    case 'error':
      return (
        <Box>
          <Alert severity="error">{message}</Alert>
        </Box>
      );
    case 'warning':
      return (
        <Box>
          <Alert severity="warning">{message}</Alert>
        </Box>
      );
    case 'info':
      return (
        <Box>
          <Alert severity="info">{message}</Alert>
        </Box>
      );
    case 'favorite':
      return (
        <Box>
          <Alert
            icon={<CheckCircleOutlineIcon />}
            handleClose={handleClose}
            severity="success"
          >
            {message}
          </Alert>
        </Box>
      );
    default:
      return null;
  }
}

const Snackbars = ({
  open,
  autoHideDuration,
  handleClose,
  variant,
  message,
  ...rest
}: any) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        left: 'auto !important',
        right: 'auto !important',
      }}
      onClose={handleClose}
      {...rest}
    >
      {getAlertByType(variant, message, handleClose)}
    </Snackbar>
  );
};

const SnackbarComponent = () => {
  const { snackbar }: any = useSelector(({ UIStore }: any) => UIStore);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(UICreators.snackbarManagement('', '', false));
  };

  return (
    <>
      {snackbar?.open && (
        <Box>
          <Snackbars
            variant={snackbar.variant}
            message={snackbar.message}
            open={snackbar.open}
            onClose={handleClose}
            autoHideDuration={snackbar.autoHideDuration}
            vertical="bottom"
            horizontal="center"
          />
        </Box>
      )}
    </>
  );
};

export default SnackbarComponent;
