import request from 'helpers/Request';
import getEnvVarByName from 'helpers/getEnvVarByName';

const domain = getEnvVarByName('API_CORPORATIVESALE_BASE_URL');

export default class UserServices {
  static getMe = () => {
    return request
      .get(encodeURI(`${domain}/employee/v1.0/me`), {})
      .then((response) => response);
  };
}
