import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  snackbarManagement: ['variant', 'message', 'open', 'autoHideDuration'],
});

export const INITIAL_STATE = {
  snackbar: {
    variant: '',
    message: '',
    open: false,
    autoHideDuration: 5000,
  },
};

export const snackbarManagement = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    snackbar: { ...state.snackbar, ...action },
  };
};

export const HANDLERS = {
  [Types.SNACKBAR_MANAGEMENT]: snackbarManagement,
};
export default createReducer(INITIAL_STATE, HANDLERS);
