import { createActions, createReducer } from 'reduxsauce';
import { boolean } from 'yup/lib/locale';

export const { Types, Creators } = createActions({
  setCurrentUserData: ['userData'],
  setDealers: ['dealers'],
  setCurrentDealer: ['currentDealer'],
  setMobicoinBalanceToCurrentDealer: ['currentBalance'],
  getMobicoinBalance: ['dealerId'],
  setFetchingBalance: ['isFetching'],
  setUserData: ['userData'],
  setMaximumNumberOfProposals: ['maximumNumberOfProposals'],
});

export const INITIAL_STATE = {
  currentUser: {
    attributes: {
      apps: [],
      locale: [],
      usersite_id: [],
    },
    email: '',
    emailVerified: false,
    firstName: '',
    lastName: '',
    username: '',
  },
  dealers: {
    content: [],
    number: 0,
    size: 0,
    totalElements: 0,
    totalPages: 0,
  },
  userData: null,
  currentDealer: {
    mobicoinBalance: 0,
  },
  maximumNumberOfProposals: null,
  isFetchingBalance: false,
};

export const setCurrentUserData = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    currentUser: action.userData,
  };
};
export const setMaximumNumberOfProposals = (
  state = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    maximumNumberOfProposals: action.maximumNumberOfProposals,
  };
};
export const setUserData = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    userData: action.userData,
  };
};

export const setDealers = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    dealers: action.dealers,
  };
};

export const setCurrentDealer = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    currentDealer: action.currentDealer,
  };
};

export const setMobicoinBalanceToCurrentDealer = (
  state = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    currentDealer: {
      ...state.currentDealer,
      mobicoinBalance: action.currentBalance,
    },
  };
};

export const setFetchingBalance = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    isFetchingBalance: action.isFetching,
  };
};

export const HANDLERS = {
  [Types.SET_CURRENT_USER_DATA]: setCurrentUserData,
  [Types.SET_DEALERS]: setDealers,
  [Types.SET_CURRENT_DEALER]: setCurrentDealer,
  [Types.SET_FETCHING_BALANCE]: setFetchingBalance,
  [Types.SET_MOBICOIN_BALANCE_TO_CURRENT_DEALER]:
    setMobicoinBalanceToCurrentDealer,
  [Types.SET_USER_DATA]: setUserData,
  [Types.SET_MAXIMUM_NUMBER_OF_PROPOSALS]: setMaximumNumberOfProposals,
};
export default createReducer(INITIAL_STATE, HANDLERS);
