import request from 'helpers/Request';
import getEnvVarByName from 'helpers/getEnvVarByName';

const domain = getEnvVarByName('API_PASSECARROS_BASE_URL');

export default class FavoritesServices {
  static getFavoriteList = () => {
    return request
      .get(`${domain}/inventory/resale/v1.0/favorite`, {})
      .then((response) => response);
  };

  static postInFavoriteList = (dealId) => {
    return request
      .post(`${domain}/inventory/resale/v1.0/favorite/${dealId}`, {})
      .then((response) => response);
  };
}
