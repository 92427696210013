import { lazy } from 'react';
import AuthComponent from 'auth/AuthComponent';
import Layout from 'layout/Layout';

//PAGES
const Home = lazy(() => import('pages/Home'));
const NotFound = lazy(() => import('pages/404'));
// const AcceptanceTerms = lazy(() => import('pagesAuth/acceptanceTerms'));
//AUTH_PAGES
const OfferList = lazy(() => import('pagesAuth/offerList'));
const DetailCar = lazy(() => import('pagesAuth/detailCar'));
const ProposalSuccess = lazy(() => import('pagesAuth/proposalSuccess'));
const BuyNowSuccess = lazy(() => import('pagesAuth/buyNowSuccess'));
const MyProposals = lazy(() => import('pagesAuth/myProposals'));
const UserProfilePanel = lazy(() => import('pagesAuth/myAccount'));
const Favorites = lazy(() => import('pagesAuth/favorites'));

export const INITIAL_PATH = '/passe-carros';

const authChildren = [
  {
    path: INITIAL_PATH,
    element: <OfferList />,
  },
  {
    path: INITIAL_PATH + '/detalhes/:dealId',
    element: <DetailCar />,
  },
  {
    path: INITIAL_PATH + '/detalhes/proposta',
    element: <ProposalSuccess />,
  },
  {
    path: INITIAL_PATH + '/detalhes/compra-realizada',
    element: <BuyNowSuccess />,
  },
  {
    path: INITIAL_PATH + '/minhas-propostas',
    element: <MyProposals />,
  },
  {
    path: INITIAL_PATH + '/favoritos',
    element: <Favorites />,
  },
  // {
  //   path: INITIAL_PATH + '/termos-de-aceite',
  //   element: <AcceptanceTerms />,
  // },
  {
    path: INITIAL_PATH + '/minha-conta',
    element: <UserProfilePanel />,
  },

  {
    path: INITIAL_PATH + '/regulamento',
    element: <UserProfilePanel />,
  },
];

const homeRoute = [
  {
    path: '/',
    element: <Home />,
  },
];

const authRoute = [
  {
    path: INITIAL_PATH,
    element: (
      <AuthComponent>
        <Layout />
      </AuthComponent>
    ),
    children: [...authChildren],
  },
];

const route404 = [
  {
    path: '*',
    element: <NotFound />,
  },
];

const routes = [...homeRoute, ...authRoute, ...route404];

export default routes;
