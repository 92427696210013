import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import { darken, lighten } from 'polished';

const COLORS = {
  CONTAINED: {
    PRIMARY: {
      bg: '#4443BC',
      hover: '#001B8B',
      color: '#fff',
    },
    PRIMARY_LIGHT: {
      bg: '#07B2FD',
      hover: darken(0.1, '#07B2FD'),
      color: '#fff',
      radius: 4,
      height: 24,
    },
    SECONDARY: {
      bg: '#00b4aa',
      hover: '#006b5d',
      color: '#fff',
    },
    ORANGE: {
      bg: '#FF9800',
      hover: '#C77700',
      color: '#fff',
    },
    PURPLE: {
      bg: '#F7F3FD',
      hover: '#F2EAFF',
      color: '#6143A0',
      borderColor: '#6143A0',
      radius: 4,
      height: 24,
    },
  },
  OUTLINED: {
    DEFAULT: {
      bg: '#FFF',
      hover: '#d5d5d5',
      color: 'rgba(0, 0, 0, 0.87)',
      borderColor: '#bdbdbd',
    },
    PRIMARY: {
      color: '#4443BC',
      bg: 'transparent',
      borderColor: '#4443BC',
      hover: '#001B8B',
    },
    PRIMARY_LIGHT: {
      color: '#07B2FD',
      bg: 'transparent',
      borderColor: '#07B2FD',
      hover: lighten(0.4, '#07B2FD'),
      radius: 8,
    },
    SECONDARY: {
      color: '#00b4aa',
      bg: 'transparent',
      borderColor: '#00b4aa',
      hover: 'rgba(0, 154, 138, 0.04)',
    },
    ORANGE: {
      bg: 'transparent',
      color: '#f1970f',
      hover: '#C77700',
      borderColor: '#f1970f',
    },
    PURPLE: {
      bg: '#F7F3FD',
      hover: '#F2EAFF',
      color: '#6143A0',
      borderColor: '#6143A0',
      radius: 8,
      height: 24,
    },
    ERROR: {
      bg: 'transparent',
      hover: '#F2EAFF',
      color: '#F44336',
      borderColor: '#F44336',
      radius: 8,
      height: 24,
    },
    TEXT: {
      DEFAULT: {
        type: 'text',
        bg: 'transparent',
        border: '0',
        color: 'rgba(0, 0, 0, 0.87)',
        hover: 'transparent',
      },
    },
  },
};

export type ButtonPropTypes = Omit<ButtonProps, 'variant' | 'type'> &
  Pick<BoxProps, 'height'> & {
    type?: 'contained' | 'outlined' | 'text';
    variant?:
      | 'default'
      | 'primary'
      | 'primary_light'
      | 'purple'
      | 'secondary'
      | 'orange'
      | 'error'
      | 'text'
      | 'default';
    isOutlined?: boolean;
    loading?: boolean;
    radius?: number;
    textTransform?: 'capitalize' | 'none' | 'lowercase' | 'uppercase';
  };

export const useStyles = makeStyles<Theme, ButtonPropTypes>((theme) => ({
  root: {
    color: (props) =>
      COLORS[props?.type.toUpperCase()]?.[props?.variant.toUpperCase()]?.color,
    textTransform: (props) => props?.textTransform,
    height: '100%',
    borderRadius: (props) =>
      props?.radius
        ? props?.radius
        : COLORS[props?.type.toUpperCase()]?.[props?.variant.toUpperCase()]
            ?.radius
        ? '4px'
        : '4px',
    backgroundColor: (props) =>
      COLORS[props?.type.toUpperCase()]?.[props?.variant.toUpperCase()]?.bg,
    '&:hover, &:focus': {
      backgroundColor: (props) =>
        COLORS[props?.type.toUpperCase()]?.[props?.variant.toUpperCase()]
          ?.hover,
      boxShadow: 'none',
      border: (props) =>
        COLORS[props?.type.toUpperCase()]?.[props?.variant.toUpperCase()]
          ?.borderColor
          ? `1px solid ${
              COLORS[props?.type.toUpperCase()]?.[props?.variant.toUpperCase()]
                ?.borderColor
            }`
          : 0,
    },
    border: (props) =>
      COLORS[props?.type.toUpperCase()]?.[props?.variant.toUpperCase()]
        ?.borderColor
        ? `1px solid ${
            COLORS[props?.type.toUpperCase()]?.[props?.variant.toUpperCase()]
              ?.borderColor
          }`
        : 0,
    boxShadow: 'none',
  },
}));

const ButtonComponent = ({
  type = 'contained',
  variant = 'primary',
  loading,
  textTransform = 'capitalize',
  radius,
  startIcon,
  onClick,
  disabled,
  fullWidth,
  children,
  height = '44px',
  ...rest
}: ButtonPropTypes) => {
  const classes = useStyles({ type, variant, radius, textTransform });

  return (
    <Box component="div" height={height}>
      <Button
        onClick={onClick}
        startIcon={startIcon}
        variant={type}
        className={classes.root}
        fullWidth={fullWidth}
        disabled={disabled || loading}
        {...rest}
      >
        {loading ? (
          <CircularProgress color="inherit" size={24} />
        ) : (
          <>{children}</>
        )}
      </Button>
    </Box>
  );
};

export default ButtonComponent;
