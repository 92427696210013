function EditSquare() {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#a)" fill="#000">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.667.732c-.69 0-1.352.274-1.84.762L6.912 9.411a.833.833 0 0 0-.22.387l-.833 3.333a.833.833 0 0 0 1.01 1.01l3.334-.833a.833.833 0 0 0 .387-.219l7.917-7.916a2.601 2.601 0 0 0-1.84-4.44zm-.661 1.94a.934.934 0 1 1 1.321 1.322l-7.753 7.754-1.762.44.44-1.762 7.754-7.753z"
        />
        <path d="M3.333 2.5A2.5 2.5 0 0 0 .833 5v11.667a2.5 2.5 0 0 0 2.5 2.5H15a2.5 2.5 0 0 0 2.5-2.5v-5.834a.833.833 0 0 0-1.667 0v5.834A.833.833 0 0 1 15 17.5H3.333a.833.833 0 0 1-.833-.833V5a.833.833 0 0 1 .833-.833h5.834a.833.833 0 0 0 0-1.667H3.333z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EditSquare;
